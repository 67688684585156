import Onboarding from "@package/steps";
import { formatMultipleListItems } from "@utils/formatting";
import { parseStep } from "@utils/onboarding";
import {
  templateValidationsMessages,
  validateTemplateMessages,
} from "@utils/template-validations/templateValidationsMessages";
import isEqual from "lodash.isequal";

import { formatListContainsOnlyOneItem, verifyDuplicate } from "../utils";

const { steps } = Onboarding;

// Check if the custom forms fields that requires an id, have it
const verifiesCustomFormFieldsIdentifier = (
  step = {},
  customFormFieldsThatRequireAnIdentifier = [],
  templateValidationNotificationsToAdd = () => [],
) => {
  let customFormFieldsThatHasNotAnIdentifier = [];

  if (!step?.fields?.length >= 1) {
    return;
  }

  step?.fields?.forEach((_field) => {
    // if the field is a custom form field that requires an identifier
    if (customFormFieldsThatRequireAnIdentifier?.includes(_field?.type) && !_field?.id) {
      let fieldType = customFormFieldsThatHasNotAnIdentifier?.find((f) => isEqual(f?.type, _field?.type));

      // if the field type already exists in the custom form fields that has not an identifier, increment the recurrence count
      if (!!fieldType) {
        fieldType = { ...fieldType, recurrence: fieldType?.recurrence + 1 };

        const customFormFieldsThatHasNotAnIdentifierUpdated = customFormFieldsThatHasNotAnIdentifier?.map((field) => {
          if (field?.type === fieldType?.type) {
            return fieldType;
          }
          return field;
        });

        customFormFieldsThatHasNotAnIdentifier = customFormFieldsThatHasNotAnIdentifierUpdated;
      } else {
        fieldType = { type: _field?.type, recurrence: 1 };
        customFormFieldsThatHasNotAnIdentifier.push(fieldType);
      }
    }
  });

  // if the custom form step has fields that requires and id and doesn't have it, format the message and add a new template validation
  if (!customFormFieldsThatHasNotAnIdentifier?.length) {
    return;
  }

  let customFormFieldsThatHasNotAnIdentifierFormatted = [];
  let customFormFieldsThatHasNotAnIdentifierFormattedMessage = "";

  customFormFieldsThatHasNotAnIdentifier?.forEach((field) => {
    if (field?.recurrence > 1) {
      customFormFieldsThatHasNotAnIdentifierFormatted.push(`${field?.type} (${field?.recurrence}x)`);
    } else {
      customFormFieldsThatHasNotAnIdentifierFormatted.push(field?.type);
    }
  });

  if (
    !(
      !!customFormFieldsThatHasNotAnIdentifierFormatted?.length &&
      customFormFieldsThatHasNotAnIdentifierFormatted?.every((field) => typeof field === "string")
    )
  ) {
    return;
  }

  customFormFieldsThatHasNotAnIdentifierFormatted = formatListContainsOnlyOneItem(
    customFormFieldsThatHasNotAnIdentifierFormatted,
  );

  if (
    typeof customFormFieldsThatHasNotAnIdentifierFormatted === "object" &&
    customFormFieldsThatHasNotAnIdentifierFormatted?.length > 1
  ) {
    customFormFieldsThatHasNotAnIdentifierFormattedMessage = formatMultipleListItems(
      customFormFieldsThatHasNotAnIdentifierFormatted,
    );
  } else if (typeof customFormFieldsThatHasNotAnIdentifierFormatted === "string") {
    customFormFieldsThatHasNotAnIdentifierFormattedMessage = customFormFieldsThatHasNotAnIdentifierFormatted;
  }

  templateValidationNotificationsToAdd.push(
    validateTemplateMessages(templateValidationsMessages.CUSTOM_FORM_FIELDS_IDENTIFIERS_MISSING)(
      customFormFieldsThatHasNotAnIdentifierFormattedMessage,
    ),
  );
};

export const customFormAndItsFieldsHaveIdentifier = (
  onboardingSteps = [],
  templateValidationNotificationsToAdd = () => [],
  defaultFlowName = "",
) => {
  let customFormStepsThatHasNotAnIdentifier = [];

  const customFormFieldsThatRequireAnIdentifier = ["checkbox", "radio", "select", "switch", "input"];

  if (!!onboardingSteps?.length) {
    onboardingSteps?.forEach((step) => {
      // If the step doesn't have an id
      if (isEqual(parseStep(step?.name)[0], steps.CUSTOM_FORM.name)) {
        if (!step?.formName) {
          customFormStepsThatHasNotAnIdentifier.push(!step?.flow?.length ? defaultFlowName : step?.flow[0]);
        }

        // If the CUSTOM_FORM step fields that requires an id, doesn't have one
        verifiesCustomFormFieldsIdentifier(
          step,
          customFormFieldsThatRequireAnIdentifier,
          templateValidationNotificationsToAdd,
        );
      }
    });
  }

  // add a new template validation if the custom form hasn't a "formName" based of each flow
  if (
    !!customFormStepsThatHasNotAnIdentifier?.length &&
    customFormStepsThatHasNotAnIdentifier?.every((flowName) => !!flowName)
  ) {
    customFormStepsThatHasNotAnIdentifier = formatListContainsOnlyOneItem(customFormStepsThatHasNotAnIdentifier);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.CUSTOM_FORM_IDENTIFIER_MISSING)(
        customFormStepsThatHasNotAnIdentifier,
      ),
    );
  }
};

export const customFormHasDuplicatedIds = (onboardingSteps = [], templateValidationNotificationsToAdd = () => []) => {
  let customFormStepsThatHasDuplicatedIds = [];
  let formNames = [];
  let inputs = [];

  if (!!onboardingSteps?.length) {
    onboardingSteps?.forEach((step, index) => {
      if (isEqual(parseStep(step?.name)[0], steps.CUSTOM_FORM.name)) {
        formNames.push(step?.formName);
        if (!inputs[index]) {
          inputs[index] = { ids: [], formName: step?.formName };
        }
        step?.fields?.forEach((field) => {
          inputs[index]?.ids?.push(field?.id);
        });
      }
    });
  }

  if (verifyDuplicate(formNames)) {
    customFormStepsThatHasDuplicatedIds = formNames.filter((item, index) => formNames.indexOf(item) !== index);
    customFormStepsThatHasDuplicatedIds = formatListContainsOnlyOneItem(customFormStepsThatHasDuplicatedIds);

    templateValidationNotificationsToAdd.push(
      validateTemplateMessages(templateValidationsMessages.CUSTOM_FORM_DUPLICATED_FORM_NAME)(
        customFormStepsThatHasDuplicatedIds,
      ),
    );
  }

  inputs?.forEach((input) => {
    if (verifyDuplicate(input?.ids)) {
      templateValidationNotificationsToAdd.push(
        validateTemplateMessages(templateValidationsMessages.CUSTOM_FORM_DUPLICATED_FIELD_ID)(
          input?.ids[0],
          input?.formName,
        ),
      );
    }
  });
};
